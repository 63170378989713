class SliderArticlesWidgetHandler extends elementorModules.frontend.handlers
    .Base {
    updateSlider() {
        const currentEle = this.$element[0]
        const sliderEle = currentEle.querySelector(
            '.home-slider-articles__slider'
        )

        if (currentEle && sliderEle) {
            new Swiper(sliderEle, {
                slidesPerView: 3,
                spaceBetween: 12,
                navigation: {
                    nextEl: currentEle.querySelector(
                        '.home-slider-articles__wrapper .slider-navigation .next button'
                    ),
                    prevEl: currentEle.querySelector(
                        '.home-slider-articles__wrapper .slider-navigation .prev button'
                    ),
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 6,
                    },
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 8,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 12,
                    },
                },
                pagination: {
                    el: currentEle.querySelector(
                        '.home-slider-articles__wrapper .slider-pagination'
                    ),
                    clickable: true,
                },
            })
        }
    }

    onInit() {
        this.updateSlider()
    }
}

window.addEventListener('elementor/frontend/init', () => {
    elementorFrontend.elementsHandler.attachHandler(
        'hiqua_home_slider_articles',
        SliderArticlesWidgetHandler
    )
})
